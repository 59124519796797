import $ from 'jquery';
import select2 from "select2";
select2();

$(function () {

    // new password form (move to accounts.css)
    if ($('#div_id_password1').hasClass('has-error')) {
        $('#div_id_password2 div span.show-hide-password ').addClass('password2-error');
    }

    // info-btn (move to Cities as that is the only place it is used)
    $('.info-btn').click(function () {
        $(this).toggleClass('btn-icon-active');
    });

});

export default class Site {

    ready = () => {
        this.initSelect2();
        this.initForms();
        this.initNavBarToggle();
        this.initFooterItems();
    }

    initSelect2 = () => {
        /**
         * transform all select elements into select2 elements
         */
        $('.select2-widget').select2();
        $("select").select2({
            width: "100%"
        });
    }

    initForms = () => {
        /**
         * initialise forms across the site
         * sets novalidate on all forms (browser does no validation on its own)
         * set various is-invalid classes (this should be CSS not JS)
         */
        $("form").each(function() {
            $(this).attr("novalidate", "");
            var objs = $(this).find(".form-group");
            objs.each(function( index ) {
                var help_block = $(".invalid-feedback", this);
                    if ($(this).hasClass('is-invalid')) {
                    $(this).find("input.form-control").addClass('is-invalid');
                    $(this).find("input.form-control").css('background-image', 'none');
                    $(this).find('input[type=checkbox]').addClass('is-invalid');
                }
            $(help_block).prepend('<i class="fas fa-exclamation-triangle"></i>');
            });
        });
    }

    initNavBarToggle = () => {
        /**
         * initialise the icon toggle for the mobile menu
         */
        $('.navbar-toggler').on('click tap', function (e) {
            e.preventDefault();
            $(this).find(".dropdown-icon").toggleClass("fas fa-bars fas fa-times");
        });
    }

    initHelpSnippets = () => {
        /**
         * Hides and shows the help text for rendered content.Snippet elements 
         */
        $('.snippet_help_link').on('click tap', function (e) {
            e.preventDefault();
            $(this).closest('div').find('.snippet_help_text').show();
            $(this).hide();
        });

        $('.hide_snippet_help_link').on('click tap', function (e) {
            e.preventDefault();
            $(this).parent().hide();
            $(this).closest('div').find('.snippet_help_link').show();
        });
    }

    initFooterItems = () => {
        /**
         * Deprecated: to be replaced with CSS
         */
        $(".footer-item").mouseenter(function () {
            $(this).addClass("footer-link-hover");
        }).mouseleave(function () {
            $(this).removeClass("footer-link-hover");
        })
    }

}
