global.bootstrap = require("bootstrap");
global.jQuery = require("jquery");
global.$ = require("jquery");
require("jquery-ui/dist/jquery-ui");
global.toastr = require("toastr");

require("../vendor/js/formset");
require("./site");
require("./users");


import Site from "./site";
const site = new Site();
jQuery(() => site.ready());
